<template>
  <div>
    <div class="grid cols-[1fr_auto]">
      <p id="filter-pickup-label">
        {{ $t.inStorePickup }}
      </p>
      <vf-switch
        v-model="isPickup"
        aria-labelledby="filter-pickup-label"
        class="row-span-2 ml-8 self-center justify-self-end"
        @update:model-value="handleSwitch"
      />
      <div class="flex gap-x-2 gap-y-1 text-xs wrap">
        <p v-if="bopis.selectedStore">
          {{ bopis.selectedStore?.name }}
        </p>
        <base-button class="underlined" @click="PanelBopisStoreSearch.keep().open(storeSearchProps)">
          {{ bopis.selectedStore ? $t.changeStore.title : $t.selectStore }}
        </base-button>
      </div>
    </div>
    <panel-bopis-store-search />
  </div>
</template>

<script lang="ts" setup>
const modelValue = defineModel<string>()

const bopis = useBopisStore()
const storeFilter = useRouteQuery<string>('storeFilter', '')

const storeSearchProps = { filterBopisStores: true, showSaveButton: true }
const isPickup = ref(!!storeFilter.value)
const PanelBopisStoreSearch = createPanel('bopis-store-search')

const handleSwitch = () => {
  if (isPickup.value && !bopis.selectedStore) {
    PanelBopisStoreSearch.keep().open(storeSearchProps).then(() => {
      if (!bopis.selectedStore) isPickup.value = false
    })
  }
  else {
    modelValue.value = isPickup.value && bopis.selectedStore ? bopis.selectedStore.storeid : undefined
  }
}

watch(() => bopis.selectedStore, () => {
  if (isPickup.value) modelValue.value = bopis.selectedStore?.storeid
})
</script>
